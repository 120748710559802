













































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import projectService from "../../../services/project-service";

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar
  },
})
export default class Employee extends Vue {
  @Prop({ type: String })
  id!: string;
  private currentItem: any = null;
  private currentIndex: number = -1;
  private title: string = "";
  private rows = 100;
  private currentPage = 1
  public create_obj : any = {};

  public fields = [
    {
      key: 'title',
      sortable: false
    },
    {
      key: 'owner',
      sortable: false
    },
    {
      key: 'branch',
      sortable: false
    },
    {
      key: 'description',
      sortable: false
    },
    {
      key: 'sub_branch',
      sortable: false,

    },
    {
      key: 'parend_id',
      sortable: false,

    },
    {
      key: 'supervisor',
      sortable: false,

    },
    {
      key: 'amount',
      sortable: false,

    },
    {
      key: 'start_date',
      sortable: false,

    },
    {
      key: 'end_date',
      sortable: false,

    },
    {
      key: 'retention_per',
      sortable: false,

    },
    {
      key: 'retention_date',
      sortable: false,

    },
    {
      key: 'comments',
      sortable: false,

    }
  ];

  public main_obj: any = {}

  retrieve() {
    projectService.get_list_by_id(this.id)
      .then((response) => {
        this.main_obj = response.data;
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  mounted() {
    this.retrieve();
  }
}
